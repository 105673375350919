<div>
  <div class="input-set-form">
    <mat-form-field (click)="onOpen('hotelClasses')">
      <div matPrefix class="mat-form-field-prefix">
        <mat-icon color="primary-dark" [svgIcon]="'star'" />
      </div>
      <mat-label class="disable-actions"> {{ 'Num. of stars' | translate }} </mat-label>
      <input
        matInput
        type="text"
        [readonly]="true"
        [value]="config.hotelClasses.value?.length ? '(' + config.hotelClasses.value?.length + ')' : null"
      />
      <div matSuffix>
        <ng-content select="[suffix]" />
      </div>
    </mat-form-field>
    <mat-form-field (click)="onOpen('hotelTypes')">
      <mat-label class="disable-actions"> {{ 'Categories' | translate }} </mat-label>
      <input
        matInput
        type="text"
        [readonly]="true"
        [value]="config.hotelTypes.value?.length ? '(' + config.hotelTypes.value?.length + ')' : null"
      />
      <div matSuffix>
        <ng-content select="[suffix]" />
      </div>
    </mat-form-field>
    <mat-form-field (click)="onOpen('mealPlans')">
      <div matPrefix class="mat-form-field-prefix">
        <mat-icon color="primary-dark" [svgIcon]="'nutrition'" />
      </div>
      <mat-label class="disable-actions"> {{ 'Meal plan' | translate }} </mat-label>
      <input
        matInput
        type="text"
        [value]="config.mealPlans.value?.length ? '(' + config.mealPlans.value?.length + ')' : null"
        [readonly]="true"
      />
      <div matSuffix>
        <ng-content select="[suffix]" />
      </div>
    </mat-form-field>
  </div>
  <div class="error-message">{{ '&nbsp;' }}</div>
</div>
<ng-template #contentTemplateRef>
  <app-kz-mobile-tab-view [activedTab]="activedTab">
    <ng-template content="hotelClasses" title="Num. of stars">
      <app-kz-mobile-multi-select-box
        class="mt-2"
        [options]="config.hotelClasses.options"
        [formControl]="formGroup.controls.hotelClasses"
      >
        <ng-template #templateOptionLabel let-option>
          @if (option.value) {
            <app-kz-stars [stars]="option.value" [hideEmptyStars]="true" />
          }
        </ng-template>
      </app-kz-mobile-multi-select-box>
    </ng-template>
    <ng-template content="hotelTypes" title="Categories">
      <app-kz-mobile-multi-select-box
        [formControl]="formGroup.controls.hotelTypes"
        [options]="config.hotelTypes.options"
      />
    </ng-template>
    <ng-template content="mealPlans" title="Meal plan">
      <app-kz-mobile-multi-select-box
        optionLabel="value"
        [formControl]="formGroup.controls.mealPlans"
        [options]="config.mealPlans.options"
      />
    </ng-template>
  </app-kz-mobile-tab-view>
</ng-template>
