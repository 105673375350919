const _formatToRegExp = {
  '0': /[0-9]/,
  a: /[a-z]/,
  A: /[A-Z]/,
  B: /[a-zA-Z]/
};

const _allFormatsStr =
  '(' +
  Object.keys(_formatToRegExp)
    .map((key) => _formatToRegExp[key as keyof typeof _formatToRegExp].toString())
    .map((regexStr) => regexStr.substr(1, regexStr.length - 2))
    .join('|') +
  ')';

const _allFormatsGlobal = getAllFormatRegexp('g');

export const valueToFormat = (value: string, format: string, goingBack = false, prevValue?: string) => {
  let maskedValue = '';
  const unmaskedValue = unmaskValue(value);

  const isLastCharFormatter = !getAllFormatRegexp().test(value[value.length - 1]);
  const isPrevLastCharFormatter = prevValue && !getAllFormatRegexp().test(prevValue[prevValue.length - 1]);

  let formatOffset = 0;
  for (let i = 0, maxI = Math.min(unmaskedValue.length, format.length); i < maxI; ++i) {
    const valueChar = unmaskedValue[i];
    let formatChar = format[formatOffset + i];
    let formatRegex = getFormatRegexp(formatChar);

    if (formatChar && !formatRegex) {
      maskedValue += formatChar;
      formatChar = format[++formatOffset + i];
      formatRegex = getFormatRegexp(formatChar);
    }

    if (valueChar && formatRegex) {
      if (formatRegex && formatRegex.test(valueChar)) {
        maskedValue += valueChar;
      } else {
        break;
      }
    }

    const nextFormatChar = format[formatOffset + i + 1];
    const nextFormatRegex = getFormatRegexp(nextFormatChar);
    const isLastIteration = i === maxI - 1;

    if (isLastIteration && nextFormatChar && !nextFormatRegex) {
      if (!isLastCharFormatter && goingBack) {
        if (prevValue && !isPrevLastCharFormatter) {
          continue;
        }
        maskedValue = maskedValue.substr(0, formatOffset + i);
      } else {
        maskedValue += nextFormatChar;
      }
    }
  }

  return maskedValue;
};

const unmaskValue = (value: string) => {
  const unmaskedMathes = value.replace(' ', '').match(_allFormatsGlobal);
  return unmaskedMathes ? unmaskedMathes.join('') : '';
};

function getAllFormatRegexp(flags?: string) {
  return new RegExp(_allFormatsStr, flags);
}

const getFormatRegexp = (formatChar: string) => {
  return formatChar && _formatToRegExp[formatChar as keyof typeof _formatToRegExp]
    ? _formatToRegExp[formatChar as keyof typeof _formatToRegExp]
    : null;
};
