<div class="input-wrapper" [ngClass]="styleClass">
  @if (label) {
    <mat-label>
      {{ label }}
    </mat-label>
  }
  <mat-form-field [ngClass]="{ 'has-error': errorMessage ? true : false }">
    @if (loading) {
      <mat-spinner class="loading-spinner" color="accent" [diameter]="15" />
    }
    @if (icon) {
      <div matPrefix class="mat-form-field-prefix">
        <mat-icon color="primary-dark" [svgIcon]="icon" />
      </div>
    }
    @if (hint || (placeholder && !hint)) {
      <mat-label class="disable-actions">
        {{ hint ?? placeholder ?? '' | translate }}
      </mat-label>
    }
    <input
      matInput
      type="text"
      [id]="inputId"
      [disabled]="disabled"
      [readonly]="true"
      [attr.enterkeyhint]="enterkeyhint"
      [value]="displayValue"
      [placeholder]="placeholder && !hint ? undefined : placeholder ? (placeholder | translate) : undefined"
      (focus)="onFocus($event)"
    />
    <div matSuffix>
      <ng-content select="[suffix]" />
    </div>
  </mat-form-field>
  <div class="error-message">{{ errorMessageText }}</div>
</div>
<ng-template #selectViewTemplateRef>
  <app-kz-mobile-multi-select-box
    [typedToken]="typedToken"
    [optionIcon]="optionIcon"
    [optionLabel]="optionLabel"
    [optionValue]="optionValue"
    [options]="options"
    [optionSearchField]="optionSearchField"
    [optionParentField]="optionParentField"
    [optionChildrenField]="optionChildrenField"
    [returnOptionsAsValue]="returnOptionsAsValue"
    [showFilter]="true"
    [optionFilterPredicate]="optionFilterPredicate"
    [optionLabelTemplate]="templateOptionLabel"
    [(ngModel)]="_selected"
    (selectedOptions)="_selectedOptions = $event"
  />
</ng-template>
