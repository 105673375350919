<div class="select-form">
  @if (label) {
    <label class="kz-label" [for]="inputId">{{ label | translate }}</label>
  }
  <select class="hidden" multiple [value]="value" [id]="inputId"></select>
  <div class="select-wrapper">
    <div
      class="select-value-wrapper"
      dynamicOverlay
      [overlayTemplate]="overlayTemplateRef"
      [overlayDisable]="disabled"
      [ngClass]="{ compact, disabled, 'has-error': errorMessage ? true : false }"
      (overlayOnToggle)="popupToggle = $event"
    >
      <div class="input-form">
        <div class="input-wrapper" [ngClass]="{ disabled }">
          @if (iconByOption) {
            <div class="input-icon">
              <mat-icon [svgIcon]="iconByOption" [ngClass]="{ iconClass }" />
            </div>
          }
          <div class="selected-value text-truncate">
            @if (!value && placeholder) {
              <span class="text-kz-grey-400">
                {{ placeholder | translate }}
              </span>
            } @else {
              @if (templateSelected) {
                @if (selectedOptionsData; as data) {
                  @for (_data of data; track index; let index = $index) {
                    <ng-container
                      [ngTemplateOutlet]="templateSelected"
                      [ngTemplateOutletContext]="{ $implicit: _data }"
                    />{{ index !== data.length - 1 ? ',' : '' }}
                  }
                }
              } @else {
                @if (selectedOptionsData; as selectedData) {
                  @for (selectedOption of selectedData; track index; let index = $index) {
                    @if (selectedOption | lodashGet: [optionValue, optionLabel, optionIcon]; as data) {
                      @if (optionIcon && data[optionIcon]) {
                        <div class="select-option-icon">
                          <mat-icon [svgIcon]="data[optionIcon]" />
                        </div>
                      }
                      {{ data[optionLabel] | translate: selectedOption
                      }}{{ index !== selectedData.length - 1 ? ',' : '' }}
                    }
                  }
                }
              }
            }
          </div>
        </div>
      </div>
      <mat-icon svgIcon="arrow-down" class="arrow-down-icon" [@fadeInOut]="popupToggle" />
    </div>
    <ng-template #overlayTemplateRef>
      <div class="select-options">
        <div class="options-list">
          @for (option of options; track index; let index = $index) {
            @if (option | lodashGet: [optionValue, optionLabel, optionIcon]; as data) {
              <mat-checkbox
                #checkBoxTrigger
                color="primary"
                class="select-option"
                [disabled]="disabled"
                [value]="data[optionValue]"
                [(ngModel)]="selected[data[optionValue]]"
                (ngModelChange)="applyChanges()"
              >
                @if (optionIcon && data[optionIcon]) {
                  <div class="select-option-icon">
                    <mat-icon [svgIcon]="data[optionIcon]" />
                  </div>
                }
                @if (templateOptionLabel) {
                  <ng-container
                    [ngTemplateOutlet]="templateOptionLabel"
                    [ngTemplateOutletContext]="{ $implicit: data }"
                  />
                }
                @if (!templateOptionLabel) {
                  {{ data[optionLabel] | translate: data }}
                }
              </mat-checkbox>
            }
          }
        </div>
        <div class="options-list-footer">
          <button type="button" (click)="toggleAll(!isSomeSelected)">
            {{ (isSomeSelected ? 'Unselect All' : 'Select All') | translate }}
          </button>
        </div>
      </div>
    </ng-template>
  </div>
  <small class="error-message">{{ errorMessageText ?? '&nbsp;' }}</small>
</div>
