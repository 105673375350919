import { CommonModule } from '@angular/common';
import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { fadeInOutAnimation } from '@core/misc/animations.misc';
import { ValidationMessage } from '@core/misc/validation.util';
import { SVGIcon } from '@data/svg-icons.data';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { KzInputDirective } from '@shared/directives/kz-input.directive';
import { DynamicOverlayModule } from '@shared/modules/dynamic-overlay/dynamic-overlay.module';
import { LodashGetPipe } from '@shared/pipes/lodash-get.pipe';
import { OrNullPipe } from '@shared/pipes/or-null.pipe';

@Component({
  selector: 'app-kz-desktop-multi-select',
  standalone: true,
  imports: [
    CommonModule,
    KzInputDirective,
    MatIconModule,
    TranslateModule,
    LodashGetPipe,
    MatCheckboxModule,
    FormsModule,
    OrNullPipe,
    DynamicOverlayModule
  ],
  templateUrl: './kz-desktop-multi-select.component.html',
  styleUrl: './kz-desktop-multi-select.component.scss',
  animations: [fadeInOutAnimation()],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => KzDesktopMultiSelectComponent)
    }
  ]
})
export class KzDesktopMultiSelectComponent<T> implements ControlValueAccessor {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  @Input() typedToken!: T;

  @Input() public hint = '';
  @Input() public placeholder = '';
  @Input() public icon?: SVGIcon;
  @Input() public iconClass?: string;

  @Input() public optionLabel: keyof T = 'label' as keyof T;
  @Input() public optionValue: keyof T = 'value' as keyof T;
  @Input() public optionIcon: string | null = '';
  @Input() public inputId = 'multi-select';
  @Input() public label = '';
  @Input() public compact = false;

  @Input() set value(value: unknown[] | null) {
    if (value !== null && value.length) {
      for (let index = 0; index < value.length; index++) this.selected[value[index] as string | number] = true;
    } else {
      this.selected = {};
    }
  }
  public get value() {
    if (!this.selected) return null;
    const keys = Object.keys(this.selected);
    if (!keys.length) return null;

    const result: unknown[] = [];
    for (let index = 0; index < keys.length; index++) if (this.selected[keys[index]] === true) result.push(keys[index]);
    if (!result.length) return null;
    return result;
  }
  @Output() readonly valueChange = new EventEmitter<unknown[] | null>();

  @Input() errorMessage?: string | ValidationErrors | null;

  @Input() public disabled = false;

  private _options: T[] = [];
  @Input() public set options(options: T[] | null) {
    this._options = this.extraOptions ? [...this.extraOptions, ...(options ?? [])] : options ?? [];
  }
  public get options() {
    return this._options;
  }
  @Input() public extraOptions: T[] | null = null;

  @ContentChild('templateSelected') public templateSelected?: TemplateRef<unknown>;
  @ContentChild('templateOptionLabel') public templateOptionLabel?: TemplateRef<unknown>;

  public selected: Record<string, boolean> = {};

  public get selectedOptionsData() {
    const selectedValues = this.value;
    if (!selectedValues) return null;

    return this.options?.filter((o) => selectedValues.includes(o[this.optionValue as keyof T])) ?? null;
  }
  public get selectedOptionLabels() {
    const selectedOptionsData = this.selectedOptionsData;
    if (!selectedOptionsData) return '';
    const labels = selectedOptionsData.map((d) => d[this.optionLabel as keyof T]);
    return labels.join(', ');
  }

  public get isSomeSelected() {
    return Object.values(this.selected).includes(true);
  }

  private touched = false;

  private _popupToggle = false;
  public get popupToggle() {
    return this._popupToggle;
  }
  public set popupToggle(state) {
    if (!state) this.markAsTouched();
    this._popupToggle = state;
  }

  public get errorMessageText() {
    if (!this.errorMessage) return;
    const errorText = typeof this.errorMessage === 'object' ? ValidationMessage(this.errorMessage) : this.errorMessage;
    if (!errorText) return;
    return typeof errorText === 'object'
      ? this.translateService.instant(errorText.text, errorText.data)
      : this.translateService.instant(errorText);
  }

  public get iconByOption() {
    if (this.value && this.options) {
      const icon = this.options.find((o) => o[this.optionValue as keyof T] === this.value)?.[
        this.optionIcon as keyof T
      ] as SVGIcon | undefined;
      if (icon) return icon;
    }
    return this.icon;
  }

  constructor(private translateService: TranslateService) {}

  public applyChanges() {
    this.markAsTouched();
    this.onChangeCallback(this.value);
  }

  public toggleAll(state: boolean) {
    if (!this.options?.length) return;
    this.selected = {};
    this.options.forEach((e) => (this.selected[e[this.optionValue as keyof T] as string] = state));
    this.applyChanges();
  }

  private markAsTouched() {
    if (!this.touched && !this.disabled) {
      this.onTouchedCallback();
      this.touched = true;
    }
  }

  /* Accessors */
  /* eslint-disable @typescript-eslint/no-empty-function */
  private onTouchedCallback: () => unknown = () => {};
  private onChangeCallback: (value: unknown) => void = () => {};

  public writeValue(value: unknown[]) {
    this.value = value;
  }
  public registerOnChange(onChange: (_: unknown) => unknown) {
    this.onChangeCallback = onChange;
  }
  public registerOnTouched(onTouched: () => unknown) {
    this.onTouchedCallback = onTouched;
  }
  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
