<div #overlayTargetElement class="date-picker-container">
  <div
    #overlayTriggerRef
    dynamicOverlay
    class="hidden"
    [overlayTargetElement]="overlayTargetElement"
    [overlayTemplate]="overlayTemplateRef"
    (overlayOnToggle)="popupToggle = $event"
    (overlayOnClose)="dynDestroySubscriptions()"
  ></div>
  <input
    kzInput
    icon="calendar"
    [id]="inputId"
    [placeholder]="(inputFocused && placeholder !== dateFormatView ? dateFormatView : placeholder) | translate"
    [iconClickHandler]="openOverlay"
    [disabled]="disabled"
    [errorState]="errorState"
    [errorMessage]="errorMessage"
    [mask]="dateMask"
    [leadZeroDateTime]="true"
    [dropSpecialCharacters]="false"
    [keepCharacterPositions]="false"
    [(ngModel)]="stringValue"
    (focus)="inputFocused = true; openOverlay()"
    (blur)="clearStringValueIfInvalid(); inputFocused = false"
  />
  <ng-template #overlayTemplateRef>
    <div class="date-picker-content" [ngClass]="{ 'double-calendar': doubleCalendar }">
      @if (placeholder) {
        <div class="calendar-header text-kz-primary">{{ placeholder | translate }}</div>
      }
      @if (dynDataIsLoading) {
        <mat-progress-bar mode="indeterminate" />
      }
      <!-- @else { -->
      <div class="flex flex-row" [ngClass]="{ 'mt-1': !dynDataIsLoading }">
        <div class="calendar-first">
          <mat-calendar
            #firstCalendarRef
            [selected]="value"
            [minDate]="minDate"
            [maxDate]="maxDate"
            [dateFilter]="filterDateFn"
            [startAt]="firstCalendarStartAt"
            [dateClass]="dateClassFn"
            (selectedChange)="value = $event; hideOverlay()"
          />
        </div>
        @if (doubleCalendar) {
          <div class="calendar-second">
            <mat-calendar
              #secondCalendarRef
              [selected]="value"
              [minDate]="minDate"
              [maxDate]="maxDate"
              [startAt]="secondCalendarStartAt"
              [dateClass]="dateClassFn"
              (selectedChange)="value = $event; hideOverlay()"
            />
          </div>
        }
      </div>
      <!-- } -->

      @if (!dynDataIsLoading && dynDataLoadError) {
        <small class="error-message">{{ dynDataLoadError | translate }}</small>
      }
      @if (templateContentFooter) {
        <ng-container [ngTemplateOutlet]="templateContentFooter" />
      }
    </div>
  </ng-template>
</div>
