import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
  forwardRef
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCalendar, MatCalendarCellClassFunction, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MaterialConfigModule } from '@core/modules/material-config.module';
import { environment } from '@env/environment';
import { TranslateModule } from '@ngx-translate/core';
import {
  DatePickerDynamicDataClass,
  DynamicDateClassLoadFn
} from '@shared/abstract-classes/date-picker-dynamic-data-class.abstract';
import { InputMaskDirective } from '@shared/directives/input-mask.directive';
import { KzInputDirective } from '@shared/directives/kz-input.directive';
import { DynamicOverlayDirective } from '@shared/modules/dynamic-overlay/dynamic-overlay.directive';
import { DynamicOverlayModule } from '@shared/modules/dynamic-overlay/dynamic-overlay.module';
import { addMonths, format, isValid, parse } from 'date-fns';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

@Component({
  selector: 'app-kz-desktop-date-picker',
  templateUrl: './kz-desktop-date-picker.component.html',
  styleUrl: './kz-desktop-date-picker.component.scss',
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    KzInputDirective,
    MatIconModule,
    TranslateModule,
    InputMaskDirective,
    FormsModule,
    MaterialConfigModule,
    DynamicOverlayModule,
    NgxMaskDirective,
    MatProgressBarModule
  ],
  standalone: true,
  providers: [
    provideNgxMask({ validation: true }),
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => KzDesktopDatePickerComponent)
    }
  ]
})
export class KzDesktopDatePickerComponent
  extends DatePickerDynamicDataClass
  implements ControlValueAccessor, AfterViewInit, OnDestroy
{
  public readonly dateFormatView = 'DD.MM.YYYY';
  @ViewChild('overlayTriggerRef', { read: DynamicOverlayDirective }) public overlayTriggerRef!: DynamicOverlayDirective;
  @ViewChild('firstCalendarRef') public set firstCalendarRefFn(ref: MatCalendar<Date>) {
    this.dynFirstCalendarRef = ref;
    this.dynInitFirstCalendarRef();
  }
  @ViewChild('firstCalendarRef', { read: ElementRef }) public set firstCalendarElementRefFn(
    element: ElementRef<HTMLElement>
  ) {
    this.dynFirstCalendarElementRef = element;
  }
  @ViewChild('secondCalendarRef') public set secondCalendarRefFn(ref: MatCalendar<Date>) {
    this.dynSecondCalendarRef = ref;
    this.dynInitSecondCalendarRef();
  }
  @ViewChild('secondCalendarRef', { read: ElementRef }) public set secondCalendarElementRefFn(
    element: ElementRef<HTMLElement>
  ) {
    this.dynSecondCalendarElementRef = element;
  }
  @ContentChild('templateContentFooter') public templateContentFooter?: TemplateRef<unknown>;

  @Input() public inputId?: string;
  @Input() public placeholder: string = this.dateFormatView;
  @Input() public header?: string;
  @Input() public minDate: Date | null = null;
  @Input() public maxDate: Date | null = null;
  @Input() public filterDateFn!: (date: Date) => boolean;
  @Input() public disabled = false;
  @Input() public errorState?: boolean;
  @Input() public errorMessage?: string | ValidationErrors | null;
  @Input() public doubleCalendar = false;
  @Input() dateClassFn!: MatCalendarCellClassFunction<Date>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public dateClassDataLoaderFn$?: DynamicDateClassLoadFn<any>;
  @Input() public context?: unknown;

  public readonly dateMask = environment.formats.fullDateMask;
  private _stringValue: string | null = null;

  @Input()
  set stringValue(value: string | Date | null) {
    if (this.disabled) return;
    if (!value) {
      this.value = null;
    } else if (typeof value === 'string' && value?.length === 10) {
      const date = parse(value, 'dd.MM.yyyy', new Date());
      if (isValid(date)) {
        this.value = date;
        this.initStartAtDates(date);
      }
    } else if (typeof value === 'string') {
      this._stringValue = value;
    } else if (isValid(value)) {
      if (!this.value) this.value = value as Date;
      else if (format(this.value as Date, 'dd.MM.yyyy') !== format(value as Date, 'dd.MM.yyyy'))
        this.value = value as Date;
    }
  }
  get stringValue() {
    return this._stringValue;
  }
  private _value: Date | null = null;
  @Input()
  public set value(date: Date | null) {
    date = date ? new Date(date.setHours(0, 0, 0, 0)) : null;
    if (date !== this._value) {
      this.onChangeCallback(date);
    }
    this._value = date;
    this.valueChange.emit(date);
    if (date === null) {
      this._stringValue = null;
      return;
    }
    const dateString = date ? format(date, 'dd.MM.yyyy') : null;
    if (dateString && this._stringValue !== dateString) this._stringValue = dateString;
  }
  public get value() {
    return this._value;
  }
  @Output() private readonly valueChange = new EventEmitter<Date | null>();

  public set popupToggle(state: boolean) {
    if (!state) {
      this.markAsTouched();
    } else {
      this.initStartAtDates();
    }
  }

  public inputFocused = false;

  public firstCalendarStartAt!: Date;
  public secondCalendarStartAt!: Date;
  private touched = false;

  public openOverlay = () => {
    this.overlayTriggerRef.show();
  };
  public isInit = false;
  constructor(public changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngAfterViewInit(): void {
    if (this.isInit) return;
    this.initStartAtDates();
    this.isInit = true;
  }

  public clearStringValueIfInvalid() {
    if (this._stringValue) {
      const date = parse(this._stringValue, 'dd.MM.yyyy', new Date());
      if (!isValid(date)) {
        this.value = null;
        this._stringValue = null;
      }
    }
  }
  public hideOverlay() {
    this.overlayTriggerRef.hide();
  }
  public initStartAtDates(date?: Date) {
    if (!this.doubleCalendar) return;
    this.firstCalendarStartAt = date ?? this.value ?? this.minDate ?? new Date();
    this.secondCalendarStartAt = addMonths(this.firstCalendarStartAt, 1);
  }

  ngOnDestroy(): void {
    this.dynDestroy();
  }
  private markAsTouched() {
    if (!this.touched) {
      this.onTouchedCallback();
      this.touched = true;
    }
  }

  /* Accessors */
  /* eslint-disable @typescript-eslint/no-empty-function */
  private onTouchedCallback: () => unknown = () => {};
  private onChangeCallback: (value: unknown) => void = () => {};

  public writeValue(value: Date) {
    if (value && typeof value === 'string') value = new Date(value);
    if (value !== this.value) this.value = value;
  }
  public registerOnChange(onChange: (_: unknown) => unknown) {
    this.onChangeCallback = onChange;
  }
  public registerOnTouched(onTouched: () => unknown) {
    this.onTouchedCallback = onTouched;
  }
  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
    if (this.overlayTriggerRef && !this.overlayTriggerRef.isHidden) this.hideOverlay();
  }
}
