import { DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Predicate,
  TemplateRef,
  ViewChild,
  forwardRef
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Enterkeyhint } from '@core/misc/utils-types.misc';
import { ValidationMessage } from '@core/misc/validation.util';
import { RequestStateModel } from '@core/store/store.models';
import { SVGIcon } from '@data/svg-icons.data';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DynamicModalModule } from '@shared/modules/dynamic-modal/dynamic-modal.module';
import { DynamicModalService } from '@shared/modules/dynamic-modal/dynamic-modal.service';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { KzMobileMultiSelectBoxComponent } from '../kz-mobile-multi-select-box/kz-mobile-multi-select-box.component';

@Component({
  selector: 'app-kz-mobile-multi-select',
  standalone: true,
  imports: [
    CommonModule,
    DynamicModalModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    TranslateModule,
    MatProgressSpinnerModule,
    KzMobileMultiSelectBoxComponent,
    FormsModule
  ],
  templateUrl: './kz-mobile-multi-select.component.html',
  styleUrl: './kz-mobile-multi-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => KzMobileMultiSelectComponent)
    }
  ]
})
export class KzMobileMultiSelectComponent<T = unknown> implements ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild('selectViewTemplateRef') selectViewTemplateRef!: TemplateRef<unknown>;
  @Input() typedToken!: T;

  @Input() label: null | string = null;
  @Input() hint: null | string = null;
  @Input() placeholder: null | string = null;
  @Input() title?: string;
  @Input() icon?: SVGIcon;

  @Input() public optionSearchField?: keyof T;
  @Input() public optionParentField?: keyof T;
  @Input() public optionChildrenField?: keyof T;

  @Input() public optionLabel: keyof T = 'label' as keyof T;
  @Input() public optionValue: keyof T = 'value' as keyof T;
  @Input() public optionIcon: keyof T = 'icon' as keyof T;

  @Input() public inputId = '';
  @Input() public nextId?: string;

  private _value: unknown[] | null = null;
  @Input() set value(value: unknown[] | null) {
    if (value && value.length) {
      if (this.returnOptionsAsValue) {
        if (!this._selectedOptions.length) this._selectedOptions = value as T[];
        this._value = (value as T[]).map((e) => e?.[this.optionValue as keyof T]);
      } else this._value = value;
      this.updateDiplayValue();
    } else {
      if (this._value) this._value = null;
      if (this._selectedOptions.length) this._selectedOptions = [];
      if (this.displayValue) this.displayValue = '';
    }
    this.changeDetectorRef.detectChanges();
  }
  get value() {
    if (this.returnOptionsAsValue) return this._selectedOptions;
    return this._value;
  }

  @Input() public enterkeyhint: Enterkeyhint = 'enter';
  @Input() styleClass?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() private readonly valueChange = new EventEmitter<any | null>();
  @Output() public readonly selectedOptions = new EventEmitter<T[]>();
  @Input() disabled = false;

  @Input() public options$?: Observable<RequestStateModel<T[]>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public onLazyOptionLoad?: (d: any) => any;
  @ContentChild('templateOptionLabel') public templateOptionLabel?: TemplateRef<unknown>;
  @Input() public returnOptionsAsValue?: boolean;

  @Input() optionFilterPredicate: Predicate<T> | null = null;

  private _options: T[] = [];
  @Input() public set options(options: T[] | null) {
    this._options = options ?? [];
    if (this.value) this.updateDiplayValue();
    this.changeDetectorRef.detectChanges();
  }
  public get options(): T[] {
    return this.extraOptions ? [...(this.extraOptions as T[]), ...(this._options ?? [])] : this._options ?? [];
  }

  @Input() public extraOptions: T[] | unknown[] | null = null;

  @Input() errorMessage?: string | ValidationErrors | null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() readonly selectedOptionData = new EventEmitter<any | null>();
  private _loading = false;
  @Input() public set loading(loading: boolean) {
    this._loading = loading;
    this.changeDetectorRef.detectChanges();
  }
  get loading() {
    return this._loading;
  }
  public _selectedOptions: T[] = [];
  public displayValue = '';
  public get errorMessageText() {
    if (!this.errorMessage) return;
    const errorText = typeof this.errorMessage === 'object' ? ValidationMessage(this.errorMessage) : this.errorMessage;
    if (!errorText) return;
    return typeof errorText === 'object'
      ? this.translateService.instant(errorText.text, errorText.data)
      : this.translateService.instant(errorText);
  }

  private _destroySubscriptions$ = new Subject<void>();
  public _selected: unknown[] | null = null;
  public isOpen = false;
  private modalRef?: DialogRef;

  constructor(
    private dynamicModalService: DynamicModalService,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.runOptionsListener();
  }

  ngOnDestroy(): void {
    this.destroySubscriptions();
  }

  private destroySubscriptions() {
    if (this._destroySubscriptions$?.next) {
      this._destroySubscriptions$.next();
      this._destroySubscriptions$.complete();
    }
  }
  public runOptionsListener() {
    if (this.options$) {
      this.options$.pipe(takeUntil(this._destroySubscriptions$)).subscribe((r) => {
        if (r.loadState.status === 'loading') this.loading = true;
        else if (this.loading) this.loading = false;
        if (r.loadState.status === 'completed') {
          this.options = this.onLazyOptionLoad ? this.onLazyOptionLoad(r.response) : (r.response as T[]);
        }
      });
    }
  }
  private updateDiplayValue() {
    this.displayValue = this.options
      .filter((e) => this._value?.includes(e[this.optionValue as keyof T]))
      .map((e) =>
        e?.[this.optionLabel as keyof T]
          ? this.translateService.instant(e[this.optionLabel as keyof T] as string)
          : null
      )
      .join(', ');
  }
  onFocus(event: FocusEvent) {
    event.preventDefault();
    this.onOpenOptions();
  }
  async onOpenOptions() {
    if (this.isOpen) return;
    if (this.loading) return;
    if (!this.selectViewTemplateRef) return;

    this.modalRef = this.dynamicModalService.open<TemplateRef<unknown>>({
      template: this.selectViewTemplateRef,
      styleClass: 'modal-clean-container',
      actions: { primaryButton: { label: 'Select' }, secondaryButton: { label: 'Close' } },
      title: this.title ?? this.label ?? this.hint ?? this.placeholder ?? ''
    }) as DialogRef;
    this.isOpen = true;
    this._selected = this.value;
    const storePreviousValues = {
      _selected: this._selected,
      _selectedOptions: this._selectedOptions
    };
    this.modalRef.closed.pipe(take(1)).subscribe((action) => {
      if (action === 'primary') {
        this.value = this._selected;
        this.valueChange.emit(this.value);
        this.selectedOptions.emit(this._selectedOptions);
        this.onChangeCallback(this.value);
      } else {
        this._selected = storePreviousValues._selected;
        this._selectedOptions = storePreviousValues._selectedOptions;
      }
      this.isOpen = false;
    });
  }
  /* Accessors requistments */

  private onTouchedCallback: () => unknown = () => {};
  private onChangeCallback: (value: unknown[] | T[] | null) => void = () => {};

  public writeValue(value: unknown[] | null) {
    this.value = value;
  }
  public registerOnChange(onChange: (_: unknown | null) => unknown) {
    this.onChangeCallback = onChange;
  }
  public registerOnTouched(onTouched: () => unknown[]) {
    this.onTouchedCallback = onTouched;
  }
  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
    this.changeDetectorRef.detectChanges();
  }
}
/*
 */
