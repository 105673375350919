import { HotelServiceEnum, HotelTypeEnum } from '@appTypes/api.types';
import { IValueLabel } from '@appTypes/common.types';

export const STATIC_OPTONS_HOTEL_SERVICES: IValueLabel<HotelServiceEnum>[] = [
  { label: 'Alcohol', icon: 'alcohol', value: HotelServiceEnum.HasAlcohol },
  { label: 'Internet', icon: 'wifi', value: HotelServiceEnum.HasFreeWifi },
  { label: 'Metro', icon: 'metro', value: HotelServiceEnum.HasMetro },
  { label: 'Pool', icon: 'pool', value: HotelServiceEnum.HasPool },
  { label: 'Mall', icon: 'mall', value: HotelServiceEnum.HasMall }
];
export const STATIC_OPTONS_HOTEL_TYPES: IValueLabel<HotelTypeEnum>[] = [
  { label: 'City Hotel', value: HotelTypeEnum.CityHotel },
  { label: 'Beach Hotel', value: HotelTypeEnum.BeachHotel },
  { label: 'Second Line', value: HotelTypeEnum.SecondLineBeach }
];
export const STATIC_OPTIONS_HOTEL_CLASSES_STARS: IValueLabel<string>[] = [
  { value: '1', label: '{{value}} Star' },
  { value: '2', label: '{{value}} Stars' },
  { value: '3', label: '{{value}} Stars' },
  { value: '4', label: '{{value}} Stars' },
  { value: '5', label: '{{value}} Stars' },
  { value: '6', label: 'Apartment' }
];
export const STATIC_OPTIONS_HOTEL_MEAL_PLANS = [
  { label: 'Room only', value: 'RO' },
  { label: 'Bed & Breakfast', value: 'BB' },
  { label: 'Half Board', value: 'HB' },
  { label: 'Full Board', value: 'FB' },
  { label: 'Full Board Transfer', value: 'FBT' },
  { label: 'All Inclusive', value: 'AL' },
];
