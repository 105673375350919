import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseInt',
  standalone: true
})
export class ParseIntPipe implements PipeTransform {
  transform(value: string | number): number {
    if (typeof value === 'string') return parseInt(value) ?? 0;
    else if (typeof value === 'number') return value ?? 0;
    return 0;
  }
}
