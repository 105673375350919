import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { Currency, HotelServiceEnum } from '@appTypes/api.types';
import { IValueLabel } from '@appTypes/common.types';
import { formatPrice } from '@core/misc/misc.utils';
import { StoreService } from '@core/store/store.service';
import { TranslateModule } from '@ngx-translate/core';
import { KzMobileInputFormComponent } from '@shared/components/mobile/kz-mobile-input-form/kz-mobile-input-form.component';
import { KzMobileMultiSelectBoxComponent } from '@shared/components/mobile/kz-mobile-multi-select-box/kz-mobile-multi-select-box.component';
import { KzMobileTabViewModule } from '@shared/modules/kz-mobile-tab-view/kz-mobile-tab-view.module';
import { DynamicModalService } from '@shared/modules/dynamic-modal/dynamic-modal.service';
import { AppPersistedState } from 'src/app/state/app.persisted.state';
import { AppState } from '../../../state/app.state';
import { KzMobileSelectComponent } from '@shared/components/mobile/kz-mobile-select/kz-mobile-select.component';
import { appSetCurrency } from 'src/app/state/app.actions';

type ActivedTabTypes = 'prices' | 'services';

interface IPricesAndServicesConfig {
  prices: { priceFromValue: number | null; priceToValue: number | null };
  hotelServices: { options: IValueLabel[]; value: string[] | null };
}
export interface IPricesAndServicesFormData {
  totalPriceFrom: number | null;
  totalPriceTo: number | null;
  hotelServices: HotelServiceEnum[] | null;
}

@Component({
  selector: 'app-mobile-price-and-services-form',
  templateUrl: './mobile-price-and-services-form.component.html',
  styleUrl: './mobile-price-and-services-form.component.scss',
  imports: [
    CommonModule,
    TranslateModule,
    MatInputModule,
    MatSliderModule,
    MatFormFieldModule,
    KzMobileTabViewModule,
    KzMobileInputFormComponent,
    KzMobileMultiSelectBoxComponent,
    ReactiveFormsModule,
    MatIconModule,
    KzMobileSelectComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class MobilePriceAndServicesFormComponent {
  @Input() config!: IPricesAndServicesConfig;

  @ViewChild('contentTemplateRef') contentTemplateRef!: TemplateRef<unknown>;

  @Output() private readonly callback = new EventEmitter<IPricesAndServicesFormData>();
  public currencyTypedToken!: Currency;
  public readonly currencies$ = this.storeService.select(AppState.currenciesResponse);
  public readonly activedCurrency$ = this.storeService.select(AppPersistedState.activedCurrency);

  public readonly formGroup = new FormGroup({
    totalPriceFrom: new FormControl<number | null>(null),
    totalPriceTo: new FormControl<number | null>(null),
    hotelServices: new FormControl<string[] | null>(null)
  });

  public maxPriceAmount = 2000;
  public activedTab: ActivedTabTypes | null = null;
  public isOpen = false;
  public currencySymbol!: string;

  get displayPrice() {
    if (this.formGroup.controls.totalPriceFrom.value !== null && this.formGroup.controls.totalPriceTo.value !== null)
      return this.formGroup.controls.totalPriceFrom.value + ' - ' + this.formGroup.controls.totalPriceTo.value;
    else if (
      this.formGroup.controls.totalPriceFrom.value === null &&
      this.formGroup.controls.totalPriceTo.value !== null
    )
      return '0 - ' + this.formGroup.controls.totalPriceTo.value;
    else if (
      this.formGroup.controls.totalPriceFrom.value !== null &&
      this.formGroup.controls.totalPriceTo.value === null
    )
      return this.formGroup.controls.totalPriceFrom.value + ' - ∞';

    return null;
  }
  constructor(
    private dynamicModalService: DynamicModalService,
    private storeService: StoreService
  ) {}

  public onOpen(activeTab: ActivedTabTypes) {
    if (this.isOpen) return;
    this.initCurrencyAndPriceData();
    this.setCurrentData();
    this.activedTab = activeTab;
    this.isOpen = true;
    const modalRef = this.dynamicModalService.open({
      template: this.contentTemplateRef,
      height: '400px',
      styleClass: 'modal-transparent-container',
      actions: { primaryButton: { label: 'Select' }, secondaryButton: { label: 'Close' } }
    });
    modalRef.componentRef?.changeDetectorRef.detectChanges();

    modalRef.closed.subscribe((action) => {
      if (action === 'primary') this.callback.emit(this.formGroup.getRawValue() as IPricesAndServicesFormData);
      else this.setCurrentData();
      this.isOpen = false;
    });
  }
  private initCurrencyAndPriceData() {
    this.currencySymbol = this.storeService.selectSnapshot(AppState.activedCurrencySymbol);
    const activedCurrency = this.storeService.selectSnapshot(AppPersistedState.activedCurrency);
    const findExchangeRate = this.storeService.selectSnapshot(
      AppState.findExchangeRate('USD', activedCurrency ?? 'USD')
    );
    this.maxPriceAmount = Math.ceil(2000 * (findExchangeRate?.rate ?? 1));
  }

  public formatLabel = (value: number) => {
    return formatPrice(value) + this.currencySymbol;
  };

  private setCurrentData() {
    this.formGroup.setValue({
      totalPriceFrom: this.config.prices.priceFromValue ?? null,
      totalPriceTo: this.config.prices.priceToValue ?? null,
      hotelServices: this.config.hotelServices.value ?? null
    });
  }

  public onCurrencySelect(currency: string) {
    this.storeService.dispatch(new appSetCurrency(currency)).then(() => {
      this.initCurrencyAndPriceData();
    });
  }

  public currencyLabelFormatterFn = (option: Currency) => {
    return `${option.code} - ${option?.symbol}`;
  };
}
