import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { HotelTypeEnum } from '@appTypes/api.types';
import { IValueLabel } from '@appTypes/common.types';
import { TranslateModule } from '@ngx-translate/core';
import { KzStarsComponent } from '@shared/components/kz-stars/kz-stars.component';
import { KzMobileMultiSelectBoxComponent } from '@shared/components/mobile/kz-mobile-multi-select-box/kz-mobile-multi-select-box.component';
import { KzMobileTabViewModule } from '@shared/modules/kz-mobile-tab-view/kz-mobile-tab-view.module';
import { DynamicModalService } from '@shared/modules/dynamic-modal/dynamic-modal.service';

type ActivedTabTypes = 'hotelClasses' | 'hotelTypes' | 'mealPlans';
interface IHotelFilterConfig {
  mealPlans: { options: IValueLabel[]; value: string[] | null };
  hotelClasses: { options: IValueLabel[]; value: string[] | null };
  hotelTypes: { options: IValueLabel[]; value: HotelTypeEnum[] | null };
}

export interface IHotelFilterFormData {
  mealPlans: string[] | null;
  hotelClasses: string[] | null;
  hotelTypes: HotelTypeEnum[] | null;
}
@Component({
  selector: 'app-mobile-hotel-filter-mobile-form',
  templateUrl: './mobile-hotel-filter-mobile-form.component.html',
  styleUrl: './mobile-hotel-filter-mobile-form.component.scss',
  imports: [
    CommonModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    KzMobileMultiSelectBoxComponent,
    TranslateModule,
    ReactiveFormsModule,
    KzMobileTabViewModule,
    KzStarsComponent
  ],
  standalone: true
})
export class MobileHotelFilterMobileFormComponent {
  @ViewChild('contentTemplateRef') contentTemplateRef!: TemplateRef<unknown>;
  @Input() config!: IHotelFilterConfig;

  public activedTab: ActivedTabTypes | null = null;

  @Output() private readonly callback = new EventEmitter<IHotelFilterFormData>();
  public readonly formGroup = new FormGroup({
    mealPlans: new FormControl<string[] | null>(null),
    hotelClasses: new FormControl<string[] | null>(null),
    hotelTypes: new FormControl<HotelTypeEnum[] | null>(null)
  });
  private isOpen = false;
  constructor(private dynamicModalService: DynamicModalService) {}

  public onOpen(activeTab: ActivedTabTypes) {
    if (this.isOpen) return;
    this.activedTab = activeTab;
    this.setCurrentData();

    const modalRef = this.dynamicModalService.open({
      template: this.contentTemplateRef,
      height: '400px',
      styleClass: 'modal-transparent-container',
      actions: { primaryButton: { label: 'Select' }, secondaryButton: { label: 'Close' } }
    });
    modalRef.closed.subscribe((action) => {
      if (action === 'primary') this.callback.emit(this.formGroup.getRawValue() as IHotelFilterFormData);
      else this.setCurrentData();
    });
  }

  private setCurrentData() {
    this.formGroup.setValue({
      mealPlans: this.config.mealPlans.value ?? null,
      hotelClasses: this.config.hotelClasses.value ?? null,
      hotelTypes: this.config.hotelTypes.value ?? null
    });
  }
}
