<div class="input-set-form">
  <mat-form-field class="w-[10rem]" (click)="onOpen('prices')">
    <div matPrefix class="mat-form-field-prefix">
      <mat-icon color="primary-dark" [svgIcon]="'moneys'" />
    </div>
    <mat-label class="disable-actions"> {{ 'Price' | translate }} </mat-label>
    <input matInput type="text" [value]="displayPrice" [readonly]="true" />
    <div matSuffix>
      <ng-content select="[suffix]" />
    </div>
  </mat-form-field>
  <mat-form-field (click)="onOpen('services')">
    <mat-label class="disable-actions"> {{ 'Services' | translate }} </mat-label>
    <input
      matInput
      type="text"
      [readonly]="true"
      [value]="config.hotelServices.value?.length ? '(' + config.hotelServices.value?.length + ')' : null"
    />
    <div matSuffix>
      <ng-content select="[suffix]" />
    </div>
  </mat-form-field>
</div>

<ng-template #contentTemplateRef>
  <app-kz-mobile-tab-view [activedTab]="activedTab">
    <ng-template content="prices" title="Price">
      <div class="flex flex-col w-full gap-4">
        <div class="flex col-row gap-4 justify-center items-center mt-4">
          <div class="w-[26%]">
            <app-kz-mobile-input-form
              type="number"
              placeholder="From"
              [min]="0"
              [formControl]="formGroup.controls.totalPriceFrom"
              [errorMessage]="null"
            />
          </div>
          <div class="pb-4 text-kz-ice-blue-400">-</div>
          <div class="w-[26%]">
            <app-kz-mobile-input-form
              type="number"
              placeholder="To"
              [min]="formGroup.controls.totalPriceFrom.value || 0"
              [formControl]="formGroup.controls.totalPriceTo"
              [errorMessage]="null"
            />
          </div>
          <div class="w-[28%] ml-3">
            <app-kz-mobile-select
              #currencySelectorRef
              optionLabel="symbol"
              optionValue="code"
              placeholder="Currency"
              icon="currency"
              [typedToken]="currencyTypedToken"
              [value]="activedCurrency$ | async"
              [options$]="currencies$"
              [labelFormatter]="currencyLabelFormatterFn"
              (valueChange)="onCurrencySelect($event)"
            >
              <ng-template #templateOptionLabel let-option> {{ option.code }} - {{ option.symbol }} </ng-template>
            </app-kz-mobile-select>
          </div>
        </div>
        <div class="flex col-col justify-center items-center mt-4">
          <div class="w-3/5">
            <mat-slider
              class="m-[0!important] w-[100%!important]"
              discrete
              [min]="0"
              [max]="maxPriceAmount"
              [displayWith]="formatLabel"
            >
              <input
                matSliderStartThumb
                [value]="formGroup.controls.totalPriceFrom.value"
                (valueChange)="formGroup.controls.totalPriceFrom.setValue($event)"
              />
              <input
                matSliderEndThumb
                [value]="formGroup.controls.totalPriceTo.value ?? maxPriceAmount"
                (valueChange)="formGroup.controls.totalPriceTo.setValue($event)"
              />
            </mat-slider>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template content="services" title="Other Services">
      <app-kz-mobile-multi-select-box
        [formControl]="formGroup.controls.hotelServices"
        [options]="config.hotelServices.options"
      />
    </ng-template>
  </app-kz-mobile-tab-view>
</ng-template>
